export const drawBackgroundPath = (
  ctx: CanvasRenderingContext2D,
  {
    diameter,
    length,
    fillColor,
    strokeColor,
  }: {
    diameter: number;
    length: number;
    fillColor: string;
    strokeColor: string;
  }
) => {
  const last = [
    [diameter, -length + diameter],
    [diameter * 1.15, -length + diameter],
    [diameter * 0.75, -length + diameter * 0.5],
  ];

  const first = [
    [0, -length + diameter],
    [-diameter * 0.15, -length + diameter],
    [diameter * 0.25, -length + diameter * 0.5],
  ];

  // Start drawing
  ctx.beginPath();
  ctx.fillStyle = fillColor;
  ctx.strokeStyle = strokeColor;


  // Draw Arc
  ctx.arc(
    diameter * 0.5,
    -length + diameter * 0.5,
    diameter * 0.5,
    0,
    Math.PI,
    false
  );

  // Draw last cubic Bézier curve
  ctx.moveTo(diameter, -length + diameter * 0.5);
  ctx.bezierCurveTo(
    last[0][0],
    last[0][1],
    last[1][0],
    last[1][1],
    last[2][0],
    last[2][1]
  );

  // Draw second cubic Bézier curve
  ctx.moveTo(0, -length + diameter * 0.5);
  ctx.bezierCurveTo(
    first[0][0],
    first[0][1],
    first[1][0],
    first[1][1],
    first[2][0],
    first[2][1]
  );

  // Finish drawing
  ctx.stroke();
};

export const drawPath = (
  ctx: CanvasRenderingContext2D,
  {
    diameter,
    length,
    fillColor,
    strokeColor,
  }: {
    diameter: number;
    length: number;
    fillColor: string;
    strokeColor: string;
  }
) => {
  // Additional path based on 'path' string not implemented as its usage is unclear
  // Only the cubic Bézier curve part is implemented here
  const last = [
    [diameter, -length + diameter],
    [diameter * 1.15, -length + diameter],
    [diameter * 0.75, -length + diameter * 0.5],
  ];

  const first = [
    [0, -length + diameter],
    [-diameter * 0.15, -length + diameter],
    [diameter * 0.25, -length + diameter * 0.5],
  ];

  ctx.beginPath();
  ctx.fillStyle = fillColor;
  ctx.strokeStyle = strokeColor;

  ctx.moveTo(0,0)
  // Draw Arc
  ctx.arc(
    diameter * 0.5,
    -length + diameter * 0.5,
    diameter * 0.5,
    0,
    Math.PI,
    false
  );

  // Draw last cubic Bézier curve
  ctx.moveTo(diameter, -length + diameter * 0.5);
  ctx.bezierCurveTo(
    last[0][0],
    last[0][1],
    last[1][0],
    last[1][1],
    last[2][0],
    last[2][1]
  );

  // Draw second cubic Bézier curve
  ctx.moveTo(0, -length + diameter * 0.5);
  ctx.bezierCurveTo(
    first[0][0],
    first[0][1],
    first[1][0],
    first[1][1],
    first[2][0],
    first[2][1]
  );
  // Draw cubic Bézier curve
  ctx.moveTo(diameter * 0.25, -length + diameter * 0.5);
  ctx.bezierCurveTo(
    diameter * 0.5,
    -length + diameter * 0.75,
    diameter * 0.5,
    -length + diameter,
    diameter * 0.75,
    -length + diameter * 0.5
  );

  // Another MoveTo for the first part of the path
  ctx.moveTo(diameter * 0.25, -length + diameter * 0.5);

  ctx.stroke();
};

export const getPath = ({
  diameter,
  length,
}: {
  diameter: number;
  length: number;
}) => {
  const last = [
    [diameter, -length + diameter],
    [diameter * 1.15, -length + diameter],
    [diameter * 0.75, -length + diameter * 0.5],
  ];

  const first = [
    [0, -length + diameter],
    [-diameter * 0.15, -length + diameter],
    [diameter * 0.25, -length + diameter * 0.5],
  ];
  return `M ${diameter * 0.5 - diameter * 0.5} ${-length + diameter * 0.5} 
    a ${diameter * 0.5} 
    ${diameter * 0.5} 0 0 1 ${diameter} 0 
    M ${0} ${-length + diameter * 0.5} 
    C ${first[0][0]} 
    ${first[0][1]} 
    ${first[1][0]} 
    ${first[1][1]} 
    ${first[2][0]} 
    ${first[2][1]}
    M ${diameter * 0.25} ${-length + diameter * 0.5}
    C ${diameter * 0.5} ${-length + diameter * 0.75}
    ${diameter * 0.5} ${-length + diameter}
    ${diameter * 0.75} ${-length + diameter * 0.5}
    M ${diameter * 0.25} ${-length + diameter * 0.5}
    M ${diameter} ${-length + diameter * 0.5} 
    C ${last[0][0]} 
    ${last[0][1]} 
    ${last[1][0]} 
    ${last[1][1]} 
    ${last[2][0]} 
    ${last[2][1]}
    
  `;
};
// export const getPath = ({
//   diameter,
//   length,
//   path,
// }: {
//   diameter: number;
//   length: number;
//   path: string;
// }) => {
//   return path 
//   // `${path} 
    
//   //   `;
// };
