import { useMemo } from "react";
import styles from "./SVGGrid.module.scss";
import { useScene } from "../Scene/Scene";
import { useSVG } from "../SVG/SVG";
import toInchOrCm from "../../helpers/toInchOrCm";
import useAubergines from "../../hooks/useAubergines";
import { RULER_WIDTH } from "../Ruler/Ruler";

const LINE_COUNT = 101;
const SVGGrid = () => {
  const { unit } = useAubergines();
  const { height } = useScene();
  const { zoom } = useSVG();
  const lines = useMemo(
    () =>
      Array.from({
        length: LINE_COUNT,
      }),
    []
  );

  const x1 = 0 + RULER_WIDTH;
  const y1 = 0;
  const x2 = 100 + RULER_WIDTH;;
  const y2 = -100;
  const strokeWidth = zoom / height;

  return (
    <>
      {lines.map((_, index) => {
        const i = toInchOrCm(index, unit);
        return (
          <line
            key={index}
            className={
              index % 10 === 0
                ? styles.gridLinePrimary
                : index % 5 === 0
                ? styles.gridLineSecondary
                : styles.gridLineTertiary
            }
            x1={x1}
            y1={-(y1 + i)}
            x2={x2}
            y2={-(y1 + i)}
            strokeWidth={strokeWidth}
          />
        );
      })}
      {lines.map((_, index) => {
        const i = toInchOrCm(index, unit);
        return (
          <line
            key={index}
            className={
              index % 10 === 0
                ? styles.gridLinePrimary
                : index % 5 === 0
                ? styles.gridLineSecondary
                : styles.gridLineTertiary
            }
            x1={x1 + i}
            y1={y1}
            x2={x1 + i}
            y2={y2}
            strokeWidth={strokeWidth}
          />
        );
      })}
    </>
  );
};

export default SVGGrid;
