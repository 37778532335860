interface TextOptions {
  fontFamily: string;
  fontSize: number;
  color: string;
  fontWeight?: string;
  x: number;
  y: number;
  width: number;
  height?: number;
  text: string;
  textAlign?: CanvasTextAlign; // Add textAlign for horizontal alignment
  textBaseline?: CanvasTextBaseline; // Add textBaseline for vertical alignment
}
function drawText(ctx: CanvasRenderingContext2D, options: TextOptions): void {
  const {
    fontFamily,
    fontSize,
    color,
    fontWeight,
    x,
    y,
    width,
    text,
    textAlign = 'start', // Default alignment if not provided
    textBaseline = 'alphabetic'
  } = options;

  // Set font properties
  ctx.font = `${fontWeight || ""} ${fontSize}px ${fontFamily}`;
  ctx.fillStyle = color;
  ctx.textAlign = textAlign;
  ctx.textBaseline = textBaseline;

  // Text positioning and wrapping logic
  const words = text.split(" ");
  const lineHeight = fontSize;
  let line = "";
  let currentY = y;

  for (const word of words) {
    const testLine = line + word + " ";
    const metrics = ctx.measureText(testLine);
    const testWidth = metrics.width;

    if (testWidth > width && line !== "") {
      ctx.fillText(line, x, currentY);
      line = word + " ";
      currentY += lineHeight;
    } else {
      line = testLine;
    }
  }

  ctx.fillText(line, x, currentY);
}

export default drawText;
