export interface SearchAndSortParams<T> {
  query: string;
  data: T[];
  keyExtractor: (item: T) => string;
}

function normalizeString(str: string): string {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s+/g, "")
    .toLowerCase();
}

function scoreMatch(query: string, target: string): number {
  if (target.includes(query)) {
    const position = target.indexOf(query);
    return target.length - position; // Higher score for matches closer to the start
  }
  return 0;
}

function searchAndSort<T>({
  query,
  data,
  keyExtractor,
}: SearchAndSortParams<T>): T[] {
  query = normalizeString(query);

  return data
    .map((item) => ({
      item,
      score: scoreMatch(query, normalizeString(keyExtractor(item))),
    }))
    .filter(({ score }) => score > 0)
    .sort((a, b) => b.score - a.score)
    .map(({ item }) => item);
}

export default searchAndSort;
