import useAubergine from "../../hooks/useAubergine";
import styles from "./AubergineHandler.module.scss";
import useMove from "../../hooks/useMove";
import { useModal } from "../ui/Modal/Modal";
import ModalOverview from "../ModalOverview/ModalOverview";
import Aubergine from "../Aubergine/Aubergine";
import { useDebounce } from "use-debounce";
import { useSVG } from "../SVG/SVG";
import useOutDetector from "../../hooks/useOutDetector";
import ModalAubergineActions from "../ModalAubergineActions/ModalAubergineActions";
import useAubergines from "../../hooks/useAubergines";
import ModalScore from "../ModalScore/ModalScore";
interface AubergineHandlerProps {
  index?: number;
}
const AubergineHandler = ({ index = 0 }: AubergineHandlerProps) => {
  const { x, w } = useSVG();
  const { unit } = useAubergines();
  const { aubergine, moveAubergine } = useAubergine(index);
  const { isDragging, ...moveHandler } = useMove({
    x: aubergine.x,
    y: aubergine.y,
    stopPropagation: true,
    onMove: moveAubergine,
    handleChangePosition: (prevPosition, delta) => ({ y: 0, x: delta.x }),
  });

  const overviewModal = useModal();
  const aubergineActionsModal = useModal();
  const scoreModal = useModal();
  const onAskEdit = () => {
    aubergineActionsModal.close();
    overviewModal.open();
  };

  const onAskScore = () => {
    aubergineActionsModal.close();
    scoreModal.open();
  };
  const [dragging] = useDebounce(isDragging, 100);
  const onAubergineClick = () => {
    if (!dragging) aubergineActionsModal.open();
  };

  useOutDetector({ aubergine, w, x });
  return (
    <>
      <g
        transform={`translate(${aubergine.x},${-aubergine.y})`}
        {...moveHandler}
        className={styles.aubergine}
        onClick={onAubergineClick}
      >
        <Aubergine
          diameter={aubergine.d}
          length={aubergine.l}
          name={aubergine.n}
        />
      </g>
      <ModalAubergineActions
        {...aubergineActionsModal}
        index={index}
        onAskEdit={onAskEdit}
        onAskScore={onAskScore}
      />
      <ModalOverview {...overviewModal} index={index} key={unit} />
      <ModalScore {...scoreModal} index={index} key={unit} />
    </>
  );
};

//<a href="https://www.flaticon.com/fr/icones-gratuites/aubergine" title="aubergine icônes">Aubergine icônes créées par Freepik - Flaticon</a>

export default AubergineHandler;
