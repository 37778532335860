import { useContext, useMemo } from "react";
import { AubergineDef, Point } from "../types/common";
import { useScene } from "../components/Scene/Scene";
import { useSVG } from "../components/SVG/SVG";
import AuberginesContext from "../contexts/AuberginesContext";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import toInchOrCm from "../helpers/toInchOrCm";

const useAubergine = (index?: number) => {
  const { t } = useTranslation();
  const { height } = useScene();
  const { zoom } = useSVG();
  const context = useContext(AuberginesContext);

  if (!context) {
    throw new Error(
      "useAubergineContext must be used within a AubergineProvider"
    );
  }
  const { setAubergines } = context;

  const moveAubergine = (point: Point) => {
    if (typeof index !== "number") {
      return;
    }
    setAubergines((aubergines) => {
      const scale = height / zoom;
      const { x = 0, y = 0 } = aubergines[index];
      const newAubergines = [...aubergines];
      let newX = parseFloat((x + point.x / scale).toFixed(3));
      if (newX < 0) newX = 0;
      newAubergines[index] = {
        ...aubergines[index],
        x: newX,
        y: parseFloat((y - point.y / scale).toFixed(3)),
      };
      return newAubergines;
    });
  };
  const aubergine = useMemo<AubergineDef>(() => {
    return context.aubergines?.[index as number] as any;
  }, [context.aubergines, index]);

  const create = (
    aubergine: Omit<AubergineDef, "x" | "y">,
    disableUnitHandling?: boolean 
  ) => {
    const unit = context.unit;
    const delta = disableUnitHandling ? 1 : 2.54;
    const l = unit === "cm" ? aubergine.l : aubergine.l * delta;
    const d = unit === "cm" ? aubergine.d : aubergine.d * delta;
    setAubergines((aubergines) => {
      const newAubergine: AubergineDef = {
        ...aubergine,
        d: parseFloat(d.toFixed(3)),
        l:parseFloat(l.toFixed(3)),
        x: 2.5,
        y: 0,
      };
      window.scrollTo(0, 0);
      toast.success(t("aubergine.create.success", { name: aubergine.n }));
      return [
        ...aubergines?.map((el) => ({ ...el, x: el.x + aubergine.d + 2.5 })),
        newAubergine,
      ];
    });
  };
  const update = (index: number, aubergine: Omit<AubergineDef, "x" | "y">) => {
    const l = toInchOrCm(aubergine.l, context.unit);

    const d = toInchOrCm(aubergine.d, context.unit);
    setAubergines((aubergines) => {
      const newAubergines = [...aubergines];
      newAubergines[index] = {
        ...newAubergines[index],
        ...aubergine,
        l: parseFloat(l.toFixed(3)),
        d: parseFloat(d.toFixed(3)),
      };
      return newAubergines;
    });

    toast.success(t("aubergine.update.success", { name: aubergine.n }));
  };
  const remove = (index: number) => {
    const name = aubergine?.n;
    setAubergines((aubergines) => {
      const newAubergines = [...aubergines];
      newAubergines.splice(index, 1);
      return newAubergines;
    });

    toast.success(t("aubergine.remove.success", { name }));
  };
  return { ...context, moveAubergine, aubergine, create, update, remove };
};

export default useAubergine;
