import Modal, { ModalProps } from "../ui/Modal/Modal";
import styles from "./ModalAubergineActions.module.scss";
import useAubergine from "../../hooks/useAubergine";
import { useTranslation } from "react-i18next";
import Menu from "../ui/Menu/Menu";
import { Icon } from "../ui/Icon/Icon";

type ModalAubergineActionsProps = ModalProps & {
  index: number;
  onAskEdit: () => void;
  onAskScore: () => void;
};

const ModalAubergineActions = ({
  index,
  onAskEdit,
  onAskScore,
  ...props
}: ModalAubergineActionsProps) => {
  const { t } = useTranslation();
  const { aubergine, remove } = useAubergine(index);
  return (
    <Modal {...props}>
      <Modal.Header
        title={`🍆 ${aubergine?.n}`}
        close={props.close}
        className={styles.header}
      />
      <Modal.Body className={styles.body}>
        <Menu>
          <Menu.Item Prefix={<Icon icon="social_leaderboard" />} onClick={onAskScore}>
            {t("modal-aubergine-actions.get-score")}
          </Menu.Item>
          <Menu.Item Prefix={<Icon icon="edit" />} onClick={onAskEdit}>
            {t("modal-aubergine-actions.edit")}
          </Menu.Item>
          <Menu.Item
            Prefix={<Icon icon="delete" />}
            onClick={() => remove(index)}
          >
            {t("modal-aubergine-actions.remove")}
          </Menu.Item>
        </Menu>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAubergineActions;
