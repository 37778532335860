import { useId, useMemo } from "react";
import styles from "./Form.module.scss";
import { Tooltip } from "react-tooltip";
import {
  ControllerProps,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  UseFormStateReturn,
  FieldValues,
  FieldPath,
} from "react-hook-form";
import { Icon } from "../Icon/Icon";

interface FormProps extends React.HTMLProps<HTMLFormElement> {
  children: React.ReactNode;
}
const Form = ({ children, className, ...props }: FormProps) => {
  return (
    <form {...props} className={`${className} ${styles.form}`}>
      {children}
    </form>
  );
};

export interface FormItemMessageProps extends React.HTMLProps<HTMLDivElement> {
  type: FormItemStatus;
  children: any;
  enableIcon?: boolean;
}

const FormItemMessage = ({
  children,
  className,
  type,
  enableIcon = true,
  ...props
}: FormItemMessageProps) => {
  const icon = useMemo(() => {
    switch (type) {
      case "error":
        return "error";
      case "success":
        return "check_circle";
      case "info":
        return "info";
      case "warning":
        return "warning";
    }
  }, [type]);
  return (
    <div
      {...props}
      className={`${className} ${styles.formItemMessage} ${
        styles[`message-${type}`]
      }`}
    >
      {enableIcon && icon && (
        <Icon icon={icon} className={styles.formItemMessageIcon} />
      )}
      {children}
    </div>
  );
};
interface FormItemProps
  extends Omit<React.HTMLProps<HTMLDivElement>, "children"> {
  label?: string;
  children?:
    | React.ReactNode
    | (({ status }: { status: FormItemStatus }) => React.ReactNode);
  required?: boolean;
  name?: string;
  LabelSuffix?: React.ReactNode;
  LabelPrefix?: React.ReactNode;
  messages?: FormItemMessageProps[];
  render?: ({ status }: { status: FormItemStatus }) => React.ReactNode;
}
export type FormItemStatus =
  | "default"
  | "error"
  | "success"
  | "info"
  | "warning";
const FormItem = ({
  children,
  label,
  required,
  className = "",
  LabelSuffix,
  LabelPrefix,
  messages = [],
  name,
  render,
}: FormItemProps) => {
  const status = useMemo<FormItemStatus>(() => {
    if (messages.some(({ type }) => type === "error")) return "error";
    if (messages.some(({ type }) => type === "warning")) return "warning";
    if (messages.some(({ type }) => type === "info")) return "info";
    if (messages.some(({ type }) => type === "success")) return "success";
    return "default";
  }, [messages]);

  const renderInput = useMemo(() => {
    return render?.({ status });
  }, [render, status]);
  return (
    <div
      className={`${styles.formItem} ${className} ${
        styles[`form-item-status-${status}`]
      }`}
    >
      <div className={`${styles.formItemHeader}`}>
        {LabelPrefix && <div className="mr-2">{LabelPrefix}</div>}
        <label htmlFor={name} className={styles.formItemLabel}>
          {label}
          {required && (
            <>
              <Icon
                icon="asterisk"
                className={styles.asterisk}
                data-tooltip-id="form-item-asterisk-tooltip"
              />
              <Tooltip id="form-item-asterisk-tooltip">
                Ce champ est requis
              </Tooltip>
            </>
          )}
        </label>
        {LabelSuffix && <div className="ml-2">{LabelSuffix}</div>}
      </div>
      {typeof children === "function" ? children({ status }) : children}
      {!!render && renderInput}
      {messages?.map((message, index) => (
        <FormItemMessage {...message} key={index} />
      ))}
    </div>
  );
};

interface FormItemInfoProps extends React.HTMLProps<HTMLDivElement> {
  children?: React.ReactNode;
}

const FormItemInfo = ({ children }: FormItemInfoProps) => {
  const id = useId();
  return (
    <>
      <Icon icon="info" className={styles.formItemInfo} data-tooltip-id={id} />
      <Tooltip id={id}>{children}</Tooltip>
    </>
  );
};
export const toFormItemMessages = (
  fieldState: ControllerFieldState
): FormItemMessageProps[] => {
  const errors = [fieldState.error?.message];
  return errors
    .filter((err) => !!err)
    .map((error) => ({
      type: "error",
      children: error,
    }));
};

interface FormControllerRender<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<TFieldValues>;
  status: FormItemStatus;
}
interface FormControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<ControllerProps<TFieldValues, TName>, "render" | "children">,
    Omit<FormItemProps, "name" | "defaultValue" | "render" | "children"> {
  render?: ({
    status,
    fieldState,
    field,
    formState,
  }: FormControllerRender<TFieldValues, TName>) => React.ReactNode;
}

const FormController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  required,
  className = "",
  LabelSuffix,
  LabelPrefix,
  messages = [],
  render,
  name,
  control,
}: FormControllerProps<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ fieldState, formState, field }) => (
        <FormItem
          LabelPrefix={LabelPrefix}
          LabelSuffix={LabelSuffix}
          name={name}
          required={required}
          label={label}
          className={className}
          messages={
            messages?.length ? messages : toFormItemMessages(fieldState)
          }
          render={({ status }) =>
            render?.({ status, fieldState, formState, field })
          }
        />
      )}
    />
  );
};
Form.Controller = FormController;
FormItem.Info = FormItemInfo;
Form.Item = FormItem;
export default Form;
