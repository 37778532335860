import styles from "./Button.module.scss";
import { ButtonProps } from "./Button.type";

const Button = ({
  variant = "transparent",
  size = "m",
  Icon,
  Prefix,
  Suffix,
  children,
  className = "",
  inline,
  type = "button",
  ...props
}: ButtonProps) => {
  return (
    <button
      {...props}
      type={type}
      className={`Button  ${styles[size]} ${styles[variant]} ${styles.button} ${
        (inline && styles.inline) || ""
      }  ${Prefix && styles.withPrefix} ${Suffix && styles.withSuffix} ${
        (Icon && styles.iconOnly) || ""
      } ${className} ${className}`}
    >
      {Prefix && <div className={styles.prefix}>{Prefix}</div>}
      {children && <div className={styles.content}>{children}</div>}
      {Suffix && <div className={styles.suffix}>{Suffix}</div>}
      {Icon}
    </button>
  );
};

export default Button;
