import { Tooltip } from "react-tooltip";
import ModalShare from "../ModalShare/ModalShare";
import { useSVG } from "../SVG/SVG";
import Button from "../ui/Button/Button";
import { Icon } from "../ui/Icon/Icon";
import { useModal } from "../ui/Modal/Modal";
import styles from "./Toolbar.module.scss";
import { useMediaQuery } from "react-responsive";
import { useScene } from "../Scene/Scene";
import { useTranslation } from "react-i18next";
import useAubergines from "../../hooks/useAubergines";

const Toolbar = () => {
  const { unit, setUnit} = useAubergines();
  const { t } = useTranslation();
  const { setX, setY, setZoom, zoom, zoomAuto } = useSVG();
  const { fullScreen, setFullScreen } = useScene();
  const goToOrigin = () => {
    setX(0);
    setY(-zoom);
  };
  const modalShare = useModal();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const buttonSize = isMobile ? "xs" : "s";
  return (
    <div className={styles.toolbar}>
      {/* <div className={styles.actions}>
        <Button
          Icon={<Icon icon="settings" />}
          size={buttonSize}
          onClick={modalShare.open}
          data-tooltip-content={t("toolbar.share")}
          data-tooltip-id="toolbar-tooltip"
        />
      </div> */}
      <div className={styles.actions}>
        <Button
          Icon={<Icon icon={fullScreen ? "fullscreen_exit" : "fullscreen"} />}
          data-tooltip-content={
            fullScreen ? t("toolbar.exit-fullscreen") : t("toolbar.fullscreen")
          }
          data-tooltip-id="toolbar-tooltip"
          size={buttonSize}
          onClick={() => setFullScreen((v) => !v)}
          className={styles.transform}
        />

        <Button
          Icon={<Icon icon="bottom_right_click" />}
          data-tooltip-content={t("toolbar.reset-coordinates")}
          data-tooltip-id="toolbar-tooltip"
          size={buttonSize}
          onClick={goToOrigin}
          className={styles.transform}
        />
        <Button
          Icon={<Icon icon="pinch_zoom_in" />}
          data-tooltip-content={t("toolbar.zoom-auto")}
          data-tooltip-id="toolbar-tooltip"
          size={buttonSize}
          onClick={() => setZoom(zoomAuto())}
          className={styles.transform}
        />

        <Button
          Icon={<Icon icon="zoom_in" />}
          size={buttonSize}
          onClick={() => setZoom((v) => v * 0.9)}
          data-tooltip-content={t("toolbar.zoom-in")}
          data-tooltip-id="toolbar-tooltip"
        />
        <Button
          Icon={<Icon icon="zoom_out" />}
          size={buttonSize}
          onClick={() => setZoom((v) => v * 1.1)}
          data-tooltip-content={t("toolbar.zoom-out")}
          data-tooltip-id="toolbar-tooltip"
        />
      </div>

      <div className={styles.actions}>
        {/* <Button
          Icon={<Icon icon="image" />}
          size={buttonSize}
          onClick={() => console.log("share")}
          data-tooltip-content={t("toolbar.create-poster")}
          data-tooltip-id="toolbar-tooltip"
        /> */}
        <Button
          Icon={unit}
          size={buttonSize}
          onClick={() => setUnit(unit === "cm" ? "inch" : "cm")}
          data-tooltip-content={t("toolbar.share")}
          data-tooltip-id="toolbar-tooltip"
        />
        <Button
          Icon={<Icon icon="share" />}
          size={buttonSize}
          onClick={modalShare.open}
          data-tooltip-content={t("toolbar.share")}
          data-tooltip-id="toolbar-tooltip"
        />
      </div>
      <ModalShare {...modalShare} />

      <Tooltip id="toolbar-tooltip" place="left" className={styles.tooltip} />
    </div>
  );
};
export default Toolbar;
