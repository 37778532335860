import { createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home/Home";

export const PATHS = {
  HOME: "/",
};
const router = createBrowserRouter([
  {
    path: PATHS.HOME,
    element: <Home />,
  },
]);
export default router;
