import { useMemo } from "react";
import styles from "./Aubergine.module.scss";
import AubergineDetails from "../AubergineDetails/AubergineDetails";
import { getPath } from "./utils";

interface AubergineProps {
  diameter?: number;
  length?: number;
  name?: string;
  showDetails?: boolean;
}



const Aubergine = ({
  diameter = 4,
  length = 15,
  name,
  showDetails = true,
}: AubergineProps) => {
  const rayon = diameter / 2;
  const tige = useMemo(() => {
    const tigeWidth = diameter * 0.1;
    const tigeHeight = diameter * 0.3;
    return {
      width: tigeWidth,
      height: tigeHeight,
      x: diameter * 0.5 - tigeWidth * 0.5,
      y: -length - tigeHeight + tigeWidth * 0.5,
      rx: tigeWidth * 0.5,
    };
  }, [diameter, length]);

  const path = useMemo(
    () => getPath({ diameter, length }),
    [diameter, length]
  );
  return (
    <>
      {showDetails && <AubergineDetails diameter={diameter} length={length} name={name}/>}
      <g className={styles.background}>
        <rect width={diameter} height={length} rx={rayon} x={0} y={-length} />

        <path d={path} />
        <rect
          {...tige}
          className={styles.tigeBackground}
          strokeLinecap="round"
        />
      </g>
      <g className={styles.aubergineGroup}>
        <rect
          width={diameter}
          height={length}
          className={styles.eggPlant}
          rx={rayon}
          x={0}
          y={-length}
        />
        <rect {...tige} className={styles.tige} strokeLinecap="round" />
        <path
          d={path}
          className={styles.eggPlantHat}
          strokeLinecap="round"
        />
      </g>
    </>
  );
};

//<a href="https://www.flaticon.com/fr/icones-gratuites/aubergine" title="aubergine icônes">Aubergine icônes créées par Freepik - Flaticon</a>

export default Aubergine;
