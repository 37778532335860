import { useState } from "react";
import styles from "./Collapse.module.scss";
import { Icon } from "../Icon/Icon";
import Button from "../Button/Button";

interface CollapseProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "defaultValue"> {
  title: any;
  defaultValue?: boolean;
  contentHeightWhenCollpased?: number;
  headerClassName?: string;
  contentClassName?: string;
}
const Collapse = ({
  children,
  title,
  defaultValue,
  contentHeightWhenCollpased = 0,
  className = "",
  headerClassName = "",
  contentClassName = "",
  ...props
}: CollapseProps) => {
  const [isOpen, setIsOpen] = useState(defaultValue);
  const contentStyle: any = {
    "--max-height": `${contentHeightWhenCollpased}px`,
  };
  return (
    <div className={`${styles.container} ${className}`}>
      <div className={`${styles.header} ${headerClassName}`}>
        {title}
        <Button
          variant="transparent"
          Icon={<Icon icon="expand_more" />}
          onClick={() => setIsOpen((v) => !v)}
          className={`${isOpen ? styles.down : styles.right}`}
        />
      </div>
      <div
        className={`${styles.content} ${
          isOpen ? styles.open : styles.close
        } ${contentClassName}`}
        style={contentStyle}
      >
        {children}
      </div>
    </div>
  );
};

export default Collapse;
