import { useEffect, useMemo, useRef } from "react";
import { useSVG } from "../components/SVG/SVG";
import { AubergineDef } from "../types/common";

const useOutDetector = ({
  aubergine,
  w,
  x,
}: {
  aubergine: AubergineDef;
  w: number;
  x: number;
}) => {
  const { setOutRight, setOutLeft } = useSVG();
  const isOutRightRef = useRef(false);
  const isOutRight = useMemo(() => {
    return aubergine.x + aubergine.d > w + x;
  }, [aubergine.d, aubergine.x, w, x]);

  useEffect(() => {
    const oldValue = isOutRightRef.current;
    isOutRightRef.current = isOutRight;
    if (oldValue === isOutRight) return;
    setOutRight((v) => {
      if (isOutRight) return v + 1;
      else if (v === 0) return v;
      return v - 1;
    });
  }, [isOutRight, setOutRight]);
  const isOutLeftRef = useRef(false);
  const isOutLeft = useMemo(() => {
    return aubergine.x < x;
  }, [aubergine.x, x]);

  useEffect(() => {
    const oldValue = isOutLeftRef.current;
    isOutLeftRef.current = isOutLeft;
    if (oldValue === isOutLeft) return;
    setOutLeft((v) => {
      if (isOutLeft) return v + 1;
      else if (v === 0) return v;
      return v - 1;
    });
  }, [isOutLeft, setOutLeft]);
};

export default useOutDetector;
