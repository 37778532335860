import Modal, { ModalProps } from "../ui/Modal/Modal";
import Button from "../ui/Button/Button";
import styles from "./ModalShare.module.scss";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDebounce } from "use-debounce";
import { useTranslation } from "react-i18next";
type ModalShareProps = ModalProps & {
  index?: number;
};
const copyToClipboard = (text: string): Promise<void> => {
  if (!navigator.clipboard) {
    console.error("Clipboard API is not available.");
    return Promise.reject("Clipboard API not available");
  }
  return navigator.clipboard.writeText(text);
};
const ModalShare = ({ index, ...props }: ModalShareProps) => {
  const {t} = useTranslation()
  const [copyStatus, setCopyStatus] = useState<boolean>(false);

  const [debouncedCopyStatus] = useDebounce(copyStatus, 5000);

  useEffect(() => {
    if (copyStatus) {
      setCopyStatus(false);
    }
  }, [copyStatus, debouncedCopyStatus]);
  const handleCopyClick = async () => {
    const textToCopy = window.location.href;
    try {
      await copyToClipboard(textToCopy);
      setCopyStatus(true);
      toast.success(t("modal-share.link-copied"));
    } catch (error) {
      toast.success(t("modal-share.error"));
    }
  };
  return (
    <Modal {...props}>
      <Modal.Header title={t('modal-share.title')} close={props.close} />
      <Modal.Body>
        <div className={styles.shareBox}>
          <div className={styles.shareLink}>{window.location.href}</div>
          <Button variant="purple" inline onClick={handleCopyClick}>
            {!copyStatus ? t("modal-share.copy") : t("modal-share.copied")}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalShare;
