import { useRef } from "react";
import styles from "./Menu.module.scss";

interface MenuProps extends React.HTMLProps<HTMLUListElement> {
  children: React.ReactNode;
  onClickOutside?: () => void;
}
const Menu = ({ children, className, onClickOutside, ...props }: MenuProps) => {
  const menuRef = useRef<HTMLUListElement>(null);
  return (
    <ul {...props} ref={menuRef} className={`${styles.menu} ${className} `}>
      {children}
    </ul>
  );
};

interface MenuItemProps extends React.HTMLProps<HTMLLIElement> {
  children: React.ReactNode;
  Prefix?: React.ReactNode;
  Suffix?: React.ReactNode;
}
const MenuItem = ({
  children,
  className,
  Prefix,
  Suffix,
  disabled,
  ...props
}: MenuItemProps) => {
  return (
    <li
      {...props}
      className={`${styles.menuItem} ${className} ${disabled ? styles.disabled : ""}`}
    >
      {!!Prefix && <div className={styles.menuItemPrefix}>{Prefix}</div>}
      {!!children && <div className={styles.menuItemContent}>{children}</div>}
      {!!Suffix && <div className={styles.menuItemSuffix}>{Suffix}</div>}
    </li>
  );
};
Menu.Item = MenuItem;
export default Menu;
