import styles from "./Card.module.scss";

type CardProps = {
  Prefix?: React.ReactNode;
  Suffix?: React.ReactNode;
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;
const Card = ({
  Prefix,
  children,
  Suffix,
  className = "",
  ...props
}: CardProps) => {
  return (
    <div {...props} className={`${styles.container} ${className}`}>
      {!!Prefix && <div>{Prefix}</div>}
      {!!children && <div>{children}</div>}
      {!!Suffix && <div>{Suffix}</div>}
    </div>
  );
};

export default Card;
