import Form from "../ui/Form/Form";
import Input from "../ui/Input/Input";
import Modal, { ModalProps } from "../ui/Modal/Modal";
import { SubmitHandler, useForm } from "react-hook-form";
import styles from "./ModalOverview.module.scss";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Button from "../ui/Button/Button";
import { useMemo } from "react";
import useAubergine from "../../hooks/useAubergine";
import { useTranslation } from "react-i18next";
import useAubergines from "../../hooks/useAubergines";

type ModalOverviewProps = ModalProps & {
  index?: number;
};

const formSchema = z.object({
  length: z
    .string()
    .regex(/^\d*\.?\d*$/, "Invalid number format")
    .min(1, "ce champ est requis"),
  diameter: z
    .string()
    .regex(/^\d*\.?\d*$/, "Invalid number format")
    .min(1, "ce champ est requis"),
  name: z
    .string()
    .min(1, "ce champ est requis")
    .max(30, "ce champ est trop long"),
});
type FormData = z.infer<typeof formSchema>;
const ModalOverview = ({ index, ...props }: ModalOverviewProps) => {
  const { t } = useTranslation();
  const { unit } = useAubergines();
  const { create, update, aubergine } = useAubergine(index);
  const d = unit === "cm" ? 1 : 2.54;
  const defaultValues = {
    length: aubergine?.l
      ? parseFloat((aubergine?.l / d)?.toFixed(2))?.toString()
      : "",
    diameter: aubergine?.d
      ? parseFloat((aubergine?.d / d)?.toFixed(2))?.toString()
      : "",
    name: aubergine?.n || "",
  };
  const { control, handleSubmit, watch } = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: "all",
  });
  const onSubmit: SubmitHandler<FormData> = async (data) => {
    index === undefined
      ? create({
          l: Number(data.length),
          d: Number(data.diameter),
          n: data.name,
        })
      : update(index, {
          l: Number(data.length),
          d: Number(data.diameter),
          n: data.name,
        });
    props?.close?.();
  };
  const diameter = Number(watch("diameter"));
  const length = Number(watch("length"));
  const name = watch("name");
  const disabled = useMemo(
    () =>
      (defaultValues.diameter === watch("diameter") &&
        defaultValues.length === watch("length") &&
        defaultValues.name === watch("name")) ||
      !diameter ||
      !length ||
      !name,
    [
      defaultValues.diameter,
      defaultValues.length,
      defaultValues.name,
      watch,
      diameter,
      length,
      name,
    ]
  );

  const title = useMemo(() => {
    return index === undefined
      ? t("modal-overview.new-aubergine")
      : `${t("modal-overview.edit")} "${aubergine?.n}"`;
  }, [aubergine?.n, index, t]);
  const buttonLabel = useMemo(() => {
    return index === undefined
      ? t("modal-overview.add")
      : t("modal-overview.edit");
  }, [index, t]);
  return (
    <Modal {...props}>
      <Modal.Header
        title={title}
        close={props.close}
        className={styles.header}
      />
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)} disabled={false}>
          <Form.Controller
            label={t("modal-overview.name")}
            name="name"
            control={control}
            render={({ status, field }) => <Input status={status} {...field} />}
          />
          <Form.Controller
            label={t("modal-overview.length", { unit })}
            name="length"
            control={control}
            required={true}
            render={({ status, field }) => <Input status={status} {...field} />}
          />
          <Form.Controller
            label={t("modal-overview.diameter", { unit })}
            name="diameter"
            required={true}
            control={control}
            render={({ status, field }) => <Input status={status} {...field} />}
          />
          <Form.Item>
            <Button variant="purple" type="submit" size="l" disabled={disabled}>
              {buttonLabel}
            </Button>
          </Form.Item>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalOverview;
