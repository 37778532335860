import { useState } from "react";
import styles from "./Input.module.scss";
import { Icon } from "../Icon/Icon";
import { FormItemStatus } from "../Form/Form";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  Prefix?: React.ReactNode;
  Suffix?: React.ReactNode;
  status?: FormItemStatus;
}

const Input = ({
  Prefix,
  Suffix,
  className = "",
  status = "default",
  ...props
}: InputProps) => {
  const [seePassword, setSeePassword] = useState(false);
  return (
    <div
      className={`${styles.inputContainer} ${className} ${
        styles[`status-${status}`]
      }`}
    >
      <input
        {...props}
        type={props.type === "password" && seePassword ? "text" : props.type}
        className={`entry ${styles.input} ${
          !!Prefix ? styles.withPrefix : ""
        } ${!!Suffix ? styles.withSuffix : ""} 
        ${styles[`input-${status}`]}`}
      />
      {!!Prefix && <div className={styles.prefix}>{Prefix}</div>}
      {!!Suffix && <div className={styles.suffix}>{Suffix}</div>}
      {props.type === "password" && (
        <button
          onClick={() => setSeePassword(!seePassword)}
          className={`${styles.seePasswordButton}`}
          type="button"
        >
          <Icon icon={seePassword ? "visibility_off" : "visibility"} />
        </button>
      )}
    </div>
  );
};
export default Input;
