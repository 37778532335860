import React, {
  useRef,
  useEffect,
  useMemo,
  useImperativeHandle,
  forwardRef,
  useState,
} from "react";
import { AubergineDef } from "../../types/common";
import getCssVariable from "../../helpers/getCssVariable";
import useAubergines from "../../hooks/useAubergines";
import applyViewBox from "../../helpers/applyViewBox";
import drawRoundedRect from "../../helpers/drawRoundRect";
import drawText from "../../helpers/drawText";
import toSizeLabel from "../../helpers/toSizeLabel";
import calculateVolume from "../../helpers/calculateVolume";
import styles from "./Poster.module.scss";
import useResizeObserver from "use-resize-observer";

function mergeWords(input: string): string[] {
  // Split the input string by whitespace
  const words = input.split(/\s+/);

  return words.reduce<string[]>((result, word) => {
    // If the current string, plus a new word, is less than or equal to 9 characters, merge them.
    if (
      result.length === 0 ||
      (result[result.length - 1] + " " + word).length > 9
    ) {
      result.push(word);
    } else {
      result[result.length - 1] += " " + word;
    }
    return result;
  }, []);
}
export interface PosterHandle {
  download: () => void;
}
type PosterProps = AubergineDef;
const Poster = forwardRef<PosterHandle, PosterProps>(({ l, d, n }, ref) => {
  const [currentScore, setCurrentScore] = useState(0);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { width = 0, height = 0 } = canvasRef.current || {};
  const { unit } = useAubergines();
  const length = l * 100;
  const diameter = d * 100;

  const w = length * 1.5;

  const titleLines = useMemo(() => {
    return mergeWords(n || "");
  }, [n]);

  const drawAubergineHat = (
    ctx: CanvasRenderingContext2D,
    { x, y }: { x: number; y: number }
  ) => {
    ctx.beginPath();
    ctx.fillStyle = getCssVariable("--green");
    ctx.strokeStyle = getCssVariable("--green");
    // Start at the beginning of the arc
    ctx.moveTo(x + diameter * 0.5, y + diameter * 0.5);

    // Draw Arc
    ctx.arc(
      x + diameter * 0.5,
      y + diameter * 0.5,
      diameter * 0.5,
      Math.PI,
      0,
      false
    );
    // Draw second cubic Bézier curve
    ctx.moveTo(x, y + diameter * 0.5);
    ctx.bezierCurveTo(
      x,
      y + diameter,
      x - diameter * 0.15,
      y + diameter,
      x + diameter * 0.25,
      y + diameter * 0.5
    );

    // Draw cubic Bézier curve
    ctx.moveTo(x + diameter * 0.25, y + diameter * 0.5);
    ctx.bezierCurveTo(
      x + diameter * 0.5,
      y + diameter * 0.75,
      x + diameter * 0.5,
      y + diameter,
      x + diameter * 0.75,
      y + diameter * 0.5
    ); // Draw last cubic Bézier curve
    ctx.bezierCurveTo(
      x + diameter * 1.15,
      y + diameter,
      x + diameter,
      y + diameter,
      x + diameter,
      y + diameter * 0.5
    );

    // Another MoveTo for the last part of the path
    ctx.moveTo(x + diameter * 0.25, y + diameter * 0.5);
    ctx.fill();
    ctx.stroke();
  };

  const drawBackground = (ctx: CanvasRenderingContext2D) => {
    ctx.fillStyle = "#fefdee";
    ctx.fillRect(0, 0, w, w);
  };

  const drawAubergine = (ctx: CanvasRenderingContext2D) => {
    const x = w * 0.85 - diameter;
    const y = (w - length) * 0.5;
    ctx.fillStyle = getCssVariable("--purple");
    drawRoundedRect(ctx, {
      x,
      y,
      height: length,
      width: diameter,
      radius: diameter * 0.5,
    });
    ctx.fillStyle = getCssVariable("--green-shade");
    const tigeWidth = diameter * 0.1;
    const tigeHeight = diameter * 0.3;
    drawRoundedRect(ctx, {
      width: tigeWidth,
      height: tigeHeight,
      x: x + diameter * 0.5 - tigeWidth * 0.5,
      y: y - tigeHeight + tigeWidth * 0.5,
      radius: tigeWidth * 0.5,
    });
    drawAubergineHat(ctx, { x, y });
  };

  const drawFrameAngles = (ctx: CanvasRenderingContext2D) => {
    ctx.fillStyle = getCssVariable("--orange");

    // Draw the rectangle
    drawRoundedRect(ctx, {
      x: w * 0.05,
      y: w * 0.05,
      width: w * 0.9,
      height: w * 0.9,
      radius: w * 0.9 * 0.1,
    });

    ctx.fillStyle = "#fefdee";
    const d = w * 0.015; // For example, fill color blue
    drawRoundedRect(ctx, {
      x: w * 0.05 + d * 0.5,
      y: w * 0.05 + d * 0.5,
      width: w * 0.9 - d,
      height: w * 0.9 - d,
      radius: (w * 0.9 - d) * 0.1,
    });
    drawRoundedRect(ctx, {
      x: 0,
      y: w * 0.2,
      width: w,
      height: w * 0.6,
      radius: 0,
    });

    drawRoundedRect(ctx, {
      x: w * 0.2,
      y: 0,
      width: w * 0.6,
      height: w,
      radius: 0,
    });

    // drawPath(ctx, {
    //   diameter: d,
    //   length: l,
    //   fillColor: "red",
    //   strokeColor: "blue",
    // });
  };
  const drawTexts = (ctx: CanvasRenderingContext2D) => {
    const textX = w * 0.15;
    let textY = w * 0.15;
    titleLines.forEach((line, index) => {
      drawText(ctx, {
        fontFamily: "Inter",
        fontSize: 15 * l,
        color: getCssVariable("--color-primary"),
        fontWeight: "900",
        x: textX,
        y: textY + (index + 1) * 15 * l,
        width: w * 0.8,
        text: line,
      });
    });
    textY += (titleLines.length + 1) * 15 * l;
    drawText(ctx, {
      fontFamily: "Inter",
      fontSize: 7 * l,
      color: getCssVariable("--color-secondary"),
      fontWeight: "700",
      x: textX,
      y: textY + w * 0.01,
      width: w * 0.8,
      text: toSizeLabel({ diameter: d, length: l, unit, only: "length" }),
    });
    drawText(ctx, {
      fontFamily: "Inter",
      fontSize: 7 * l,
      color: getCssVariable("--color-secondary"),
      fontWeight: "700",
      x: textX,
      y: textY + w * 0.01 + 8 * l,
      width: w * 0.8,
      text: toSizeLabel({ diameter: d, length: l, unit, only: "diameter" }),
    });

    drawText(ctx, {
      fontFamily: "Inter",
      fontSize: 7 * l,
      color: getCssVariable("--color-primary"),
      fontWeight: "700",
      x: textX,
      y: textY + w * 0.01 + 20 * l,
      width: w * 0.8,
      text: "SCORE",
    });
    drawText(ctx, {
      fontFamily: "Inter",
      fontSize: 20 * l,
      color: getCssVariable("--color-primary"),
      fontWeight: "900",
      x: textX,
      y: textY + w * 0.01 + 38 * l,
      width: w * 0.8,
      text: currentScore.toFixed(0),
    });
    drawText(ctx, {
      fontFamily: "Inter",
      fontSize: 3.5 * l,
      color: getCssVariable("--orange"),
      fontWeight: "700",
      x: w * 0.5,
      y: w * 0.05,
      width: w * 0.7,
      text: "AubergineComparator",
      textAlign: "center",
      textBaseline: "top",
    });
    drawText(ctx, {
      fontFamily: "Inter",
      fontSize: 3.5 * l,
      color: getCssVariable("--orange"),
      fontWeight: "700",
      x: w * 0.5,
      y: w * 0.95,
      width: w * 0.7,
      text: "AubergineComparator",
      textAlign: "center",
      textBaseline: "bottom",
    });
  };

  useEffect(() => {
    let frameId: any;
    const finalScore = parseInt(calculateVolume(d, l).toFixed(0)); // Replace this with the actual final score
    const duration = 1000; // Duration of the animation in milliseconds
    const startTime = Date.now();

    const animate = () => {
      const elapsedTime = Date.now() - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      setCurrentScore(finalScore * progress);

      if (progress < 1) {
        frameId = requestAnimationFrame(animate);
      }
    };

    frameId = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(frameId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      if (ctx) {
        ctx?.clearRect(0, 0, width, height);
        applyViewBox(canvasRef, {
          viewX: 0,
          viewY: 0,
          viewWidth: w,
          viewHeight: w,
        });
        drawBackground(ctx);
        drawFrameAngles(ctx);
        drawAubergine(ctx);
        drawTexts(ctx);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [l, d, n, width, height, currentScore]); // Add dependencies here

  useEffect(() => {}, [w]);

  const download = () => {
    if (canvasRef.current) {
      const dataUrl = canvasRef.current.toDataURL("image/png");
      const link = document.createElement("a");
      link.download = n + ".png";
      link.href = dataUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  useImperativeHandle(ref, () => ({
    download,
  }));

  const resizeObserver = useResizeObserver<HTMLDivElement>();
  return (
    <div className={styles.container} ref={resizeObserver.ref}>
      {resizeObserver?.width && resizeObserver?.width > 0 && (
        <canvas
          ref={canvasRef}
          width={resizeObserver.width}
          height={resizeObserver.width}
        />
      )}
    </div>
  );
});

export default Poster;
