import { useState } from "react";
import Card from "../Card/Card";
import Button from "../ui/Button/Button";
import styles from "./Sidebar.module.scss";
import ModalOverview from "../ModalOverview/ModalOverview";
import { useModal } from "../ui/Modal/Modal";
import Input from "../ui/Input/Input";
import { Icon } from "../ui/Icon/Icon";
import toSizeLabel from "../../helpers/toSizeLabel";
import data from "../../assets/aubergines.json";
import useAubergine from "../../hooks/useAubergine";
import { useTranslation } from "react-i18next";
import searchAndSort from "../../helpers/search";
import useAubergines from "../../hooks/useAubergines";
import ModalWarning from "../ModalWarning/ModalWarning";

type TAubergineListItem = {
  name: string;
  length: number;
  diameter: number;
};
const AUBERGINES = data.aubergines as TAubergineListItem[];
const Sidebar = () => {
  const { t } = useTranslation();
  const { unit } = useAubergines();
  const aubergine = useAubergine();
  const modalOverview = useModal();
  const [search, setSearch] = useState<string>("");
  const [showWarning] = useState<boolean>(true);
  const filteredAubergines = searchAndSort({
    query: search,
    data: AUBERGINES,
    keyExtractor: (item) => item.name,
  });
  const warningModal = useModal();
  return (
    <div className={styles.sidebar}>
      <h1 className={styles.sidebarTitle}>{t("sidebar.title")}</h1>
      <Button variant="purple" onClick={modalOverview.open}>
        {t("sidebar.add-my-aubergine")}
      </Button>
      {modalOverview.isOpen && <ModalOverview {...modalOverview} />}
      <div className={styles.orContainer}>
        <div className={styles.orLabel}>{t("sidebar.or")}</div>
      </div>
      <Input
        className={styles.searchInput}
        Prefix={<Icon icon="search" />}
        placeholder={t("sidebar.search-placeholder")}
        value={search}
        onChange={(e) => setSearch(e.currentTarget.value)}
      />
      <div className={styles.cards}>
        {filteredAubergines.map(({ name, length, diameter }) => (
          <Card
            key={name + length + diameter}
            className={styles.card}
            onClick={() =>
              aubergine.create(
                {
                  l: Number(length),
                  d: Number(diameter),
                  n: name,
                },
                true
              )
            }
          >
            <div className={styles.cardTitle}>{name}</div>
            <div className={styles.cardSubtitle}>
              {toSizeLabel({ length, diameter, unit })}
            </div>
          </Card>
        ))}
      </div>
      {showWarning && (
        <div
          className={styles.warning}
          onClick={() => {
            warningModal.open();
          }}
        >
          <img
            src="/aubergine.png"
            alt="aubergine"
            className={styles.aubergineImg}
          />
          <div className={styles.warningMessage}>
            {t("sidebar.warning-message")}
          </div>

          <Button
            variant="transparent"
            className={styles.warningClose}
            size="xs"
            Icon={<Icon icon="close" className={styles.warningCloseIcon} />}
            onClick={() => {
              warningModal.open();
            }}
          />
        </div>
      )}
      <div className={styles.email}>Contact: auberginecomparator@gmail.com</div>
      <ModalWarning {...warningModal} />
    </div>
  );
};

export default Sidebar;
