import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import SVGGrid from "../SVGGrid/SVGGrid";
import { useScene } from "../Scene/Scene";
import { animated, useSpring } from "@react-spring/web";

import Toolbar from "../Toolbar/Toolbar";
// import useMove from "../../hooks/useMove";
import styles from "./SVG.module.scss";
import useBoundedState from "../../hooks/useBoundedState";
import useAubergines from "../../hooks/useAubergines";
import Ruler from "../Ruler/Ruler";
import Button from "../ui/Button/Button";
import { Icon } from "../ui/Icon/Icon";

type TSVGContext = {
  x: number;
  setX: React.Dispatch<React.SetStateAction<number>>;
  y: number;
  setY: React.Dispatch<React.SetStateAction<number>>;
  zoom: number;
  setZoom: React.Dispatch<React.SetStateAction<number>>;
  zoomAuto: () => number;
  w: number;
  h: number;
} & ReturnType<typeof useOut>;
const SVGContext = createContext<TSVGContext>({
  x: 0,
  setX: () => {},
  y: 0,
  setY: () => {},
  zoom: 1,
  setZoom: () => {},
  zoomAuto: () => 25,
  w: 0,
  h: 0,
  outLeft: 0,
  setOutLeft: () => {},
  outRight: 0,
  setOutRight: () => {},
});

const useOut = () => {
  const [outLeft, setOutLeft] = useState<number>(0);
  const [outRight, setOutRight] = useState<number>(0);
  return {
    outLeft,
    setOutLeft,
    outRight,
    setOutRight,
  };
};
type SVGProps = {
  children: React.ReactNode;
};
const SVG = ({ children }: SVGProps) => {
  const out = useOut();
  const { ratio, height, width } = useScene();
  const { aubergines } = useAubergines();

  const zoomAuto = useCallback(() => {
    const arr = aubergines?.length
      ? aubergines.map?.((aubergine) => aubergine.l * 1.5)
      : [25];
    const test = Math.max(...arr);
    return test;
  }, [aubergines]);
  const [zoom, setZoom] = useBoundedState(zoomAuto(), {
    min: 1,
    max: ratio > 1 ? 100 / ratio : 100,
  });

  useEffect(() => {
    setZoom(zoomAuto());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aubergines.length]);

  const screenReady = !!height && !!width;
  const w = useMemo(() => zoom * ratio, [zoom, ratio]);
  const h = zoom;

  const [x, setX] = useBoundedState(0, { min: 0, max: 100 - w });
  const [y, setY] = useBoundedState(0, {
    min: -h, //-100,
    max: -h, //-h
  });

  const viewBox = useSpring({
    viewBox: `${x} ${y} ${w} ${h}`,
    config: {
      mass: 1,
      tension: 100,
      friction: 100,
      duration: 300,
    },
  });
  // const { ...moveHandler } = useMove({
  //   x,
  //   y,
  //   disabled: true,
  //   onMove: (delta) => {
  //     const scale = height / zoom;
  //     setY((v) => v - delta.y / scale);
  //     setX((v) => v - delta.x / scale);
  //   },
  //   handleChangePosition: (prevPosition, delta) => delta,
  // });
  return (
    <SVGContext.Provider
      value={{
        x,
        setX,
        y,
        setY,
        w,
        h,
        zoom,
        setZoom,
        zoomAuto,
        ...out,
      }}
    >
      {screenReady && (
        <animated.svg
          className={styles.container}
          width={width}
          height={height}
          {...viewBox}
          // {...moveHandler}
        >
          <SVGGrid />
          <Ruler />
          {children}
        </animated.svg>
      )}
      <Toolbar />
      {(out.outLeft > 0 || x > 0) && (
        <Button
          className={styles.scrollButtonLeft}
          variant="dark"
          Icon={<Icon icon="chevron_left" />}
          onClick={() => setX((v) => v - 1)}
        />
      )}
      {out.outRight > 0 && (
        <Button
          className={styles.scrollButtonRight}
          variant="dark"
          Icon={<Icon icon="chevron_right" />}
          onClick={() => setX((v) => v + 1)}
        />
      )}
    </SVGContext.Provider>
  );
};

const useSVG = () => useContext(SVGContext);
export { useSVG };
export default SVG;
