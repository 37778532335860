import { RouterProvider } from "react-router-dom";
import "./App.scss";
import router from "./router";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { SpeedInsights } from "@vercel/speed-insights/react"

function App() {
  return (
    <I18nextProvider i18n={i18n} defaultNS={"translation"}>
      <RouterProvider router={router} />
      <SpeedInsights />
    </I18nextProvider>
  );
}

export default App;
