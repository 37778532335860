import toSizeLabel from "../../helpers/toSizeLabel";
import useAubergines from "../../hooks/useAubergines";
import styles from "./AubergineDetails.module.scss";

const FONT_SIZE = 1;

interface AubergineDetailsProps {
  length: number;
  diameter: number;
  name: any;
}
const AubergineDetails = ({
  diameter,
  length,
  name,
}: AubergineDetailsProps) => {
  const { unit } = useAubergines();
  const lineDef = {
    x1: diameter * 0.5,
    x2: 0,
    y1: -length * 0.5,
    y2: -length - 2.5,
  };
  const labelPosition = {
    x: lineDef.x2 + FONT_SIZE * 0.1,
    y: lineDef.y2 - FONT_SIZE * 0.1,
  };
  const subLabelPosition = {
    x: labelPosition.x,
    y: labelPosition.y + FONT_SIZE * 0.75,
  };
  return (
    <>
      <text
        {...labelPosition}
        width={diameter}
        className={styles.label}
        style={{ fontSize: FONT_SIZE }}
      >
        {name}
      </text>

      <text
        {...subLabelPosition}
        className={styles.subLabel}
        style={{ fontSize: FONT_SIZE * 0.5 }}
      >
        {toSizeLabel({ diameter, length, unit })}
      </text>
    </>
  );
};
export default AubergineDetails;
