import { TUnit } from "../types/common";

type ToSizeLabelParams = {
  diameter: any;
  length: any;
  unit: TUnit;
  only?: "diameter" | "length";
};
const toSizeLabel = ({ diameter, length, unit, only }: ToSizeLabelParams) => {
  const l = parseFloat(
    (unit === "inch" ? Number(length) / 2.54 : Number(length)).toFixed(2)
  );
  const d = parseFloat(
    (unit === "inch" ? Number(diameter) / 2.54 : Number(diameter)).toFixed(2)
  );
  const u = unit === "inch" ? "in" : "cm";
  if (only === "diameter") return `🚫 ${d}${u}`;
  if (only === "length") return `⬆️ ${l}${u}`;
  return `⬆️ ${l}${u}, 🚫 ${d}${u}`;
};
export default toSizeLabel;
