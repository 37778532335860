import useResizeObserver from "use-resize-observer";
import SVG from "../SVG/SVG";
import styles from "./Scene.module.scss";
import { Helmet } from "react-helmet";
import { createContext, useContext, useMemo, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import AubergineHandler from "../AubergineHandler/AubergineHandler";
import { AuberginesProvider } from "../../contexts/AuberginesContext";
import useAubergines from "../../hooks/useAubergines";
import useBodyScrollLock from "../../hooks/useBodyScrollLock";
import { useTranslation } from "react-i18next";

type SceneContextProps = {
  height: number;
  width: number;
  ratio: number;
  fullScreen: boolean;
  setFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
};
const SceneContext = createContext<SceneContextProps>({
  width: 0,
  height: 0,
  ratio: 1,
  fullScreen: false,
  setFullScreen: () => {},
});

const Scene = () => {
  const resizeObserver = useResizeObserver<HTMLDivElement>();
  const { t, i18n } = useTranslation("seo");
  const [fullScreen, setFullScreen] = useState(false);
  const { width = 0, height = 0 } = resizeObserver;
  const ratio = useMemo(() => width / height, [width, height]);

  useBodyScrollLock(fullScreen);
  return (
    <>
      <Helmet>
        <html lang={i18n.language} />
        <title>{t("title")}</title>
        <meta name="description" content={t("description")} />
        <meta property="og:title" content={t("ogTitle")} />
        <meta property="og:description" content={t("ogDescription")} />
        <meta property="og:image" content={t("ogImage")} />
        <meta property="og:url" content={t("ogUrl")} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={t("title")} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={t("twitterTitle")} />
        <meta name="twitter:description" content={t("twitterDescription")} />
        <meta name="twitter:image" content={t("twitterImage")} />
        {/* Additional dynamic tags */}
      </Helmet>
      <div className={styles.layout}>
        <AuberginesProvider>
          <SceneContext.Provider
            value={{ width, height, ratio, fullScreen, setFullScreen }}
          >
            <div
              className={`${styles.sceneContainer} ${
                fullScreen ? styles.fullScreen : ""
              }`}
            >
              <div ref={resizeObserver.ref} className={styles.scene}>
                <SVG>
                  <Aubergines />
                </SVG>
              </div>
            </div>
          </SceneContext.Provider>

          {!fullScreen && (
            <div className={styles.sidebarContainer}>
              <Sidebar />
            </div>
          )}
        </AuberginesProvider>
      </div>
    </>
  );
};

const Aubergines = () => {
  const { aubergines } = useAubergines();
  return (
    <>
      {aubergines?.map((aubergine, index) => (
        <AubergineHandler index={index} key={index + (aubergine.n || "")} />
      ))}
    </>
  );
};
export const useScene = () => useContext(SceneContext);
export default Scene;
