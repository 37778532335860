import Modal, { ModalProps } from "../ui/Modal/Modal";
import styles from "./ModalScore.module.scss";
import { useTranslation } from "react-i18next";
import useAubergine from "../../hooks/useAubergine";
import useWindowSize from "react-use/lib/useWindowSize";

import Poster, { PosterHandle } from "../Poster/Poster";
import Confetti from "react-confetti";
import { useDebounce } from "use-debounce";
import { useRef } from "react";
import Button from "../ui/Button/Button";
type ModalScoreProps = ModalProps & {
  index?: number;
};

const ModalScore = ({ index, ...props }: ModalScoreProps) => {
  const { width, height } = useWindowSize();
  const { t } = useTranslation();
  const { aubergine } = useAubergine(index);

  const [started, ] = useDebounce(!!props.isOpen, 700);
  const [stopped] = useDebounce(!!props.isOpen, 10000);

  const posterRef = useRef<PosterHandle>(null);

  const download = () => {
    posterRef.current?.download();
  };
  return (
    <Modal {...props}>
      <Modal.Header
        title={t("modal-score.title", { name: aubergine.n })}
        close={props.close}
        className={styles.header}
      />
      <div className={styles.body}>
        <Poster {...aubergine} ref={posterRef} />
        {started && (
          <Confetti
            width={width}
            height={height}
            numberOfPieces={!stopped && props.isOpen ? 200 : 0}
          />
        )}
        <div className={styles.footer}>
          <Button
            className={styles.downloadButton}
            variant="transparent"
            inline
            onClick={download}
          >
            {t("modal-score.download")}
          </Button>
        </div>
        
      </div>
    </Modal>
  );
};

export default ModalScore;
