import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { AubergineDef, TUnit } from "../types/common";
import { useDebounce } from "use-debounce";
import { useTranslation } from "react-i18next";
import getDefaultUnitByLocale from "../helpers/getDefaultUnitByLocale";
import AUBERGINES from "../assets/aubergines.json"

// Define the context type
type TAuberginesContextType = {
  aubergines: AubergineDef[];
  setAubergines: Dispatch<SetStateAction<AubergineDef[]>>;
  unit: TUnit;
  setUnit: (unit: TUnit) => void;
};

// Create the context with an initial value
const AuberginesContext = createContext<TAuberginesContextType | undefined>(
  undefined
);

// Define a custom provider for the context
type AubergineProviderProps = {
  children: any;
};
function getStringBetween(
  text: string,
  start: string,
  end: string
): string | null {
  const startIndex = text.indexOf(start);
  if (startIndex === -1) {
    return null; // 'start' not found in the text
  }

  // Add start.length to skip the 'start' substring itself
  const endIndex = text.indexOf(end, startIndex + start.length);
  if (endIndex === -1) {
    return null; // 'end' not found in the text
  }

  // Extract the substring between the two indices
  return text.substring(startIndex + start.length, endIndex) || "";
}
function getAllCharactersAfter(
  text: string,
  searchString: string
): string | null {
  const index = text.indexOf(searchString);

  if (index === -1) {
    return null; // searchString not found in text
  }

  // Extract and return the substring after the occurrence
  return text.substring(index + searchString.length);
}

function parseAubergine(str: string): AubergineDef {
  const resultat: AubergineDef = {
    l: parseFloat(getStringBetween(str, "l", "d") as string),
    d: parseFloat(getStringBetween(str, "d", "x") as string),
    x: parseFloat(getStringBetween(str, "x", "y") as string),
    y: parseFloat(getStringBetween(str, "y", "n") as string),
    n: getAllCharactersAfter(str, "n") as string,
  };

  return resultat;
}

function parseAubergines(str: string = ""): AubergineDef[] {
  const strSplit = str?.split("_").filter((el) => !!el) || [];
  const test = strSplit?.map(parseAubergine);
  return test;
}

const auberginesInURL = (aubergines: AubergineDef[] = []) => {
  return aubergines
    ?.map((aubergine) => {
      const { l = 10, d = 3, x = 0, y = 0, n = "" } = aubergine;
      let str = "";
      if (l !== undefined) str += `l${l}`;
      if (d !== undefined) str += `d${d}`;
      if (x !== undefined) str += `x${x}`;
      if (y !== undefined) str += `y${y}`;
      if (n !== undefined) str += `n${n}`;
      return str;
    })
    ?.join("_");
};

export const AuberginesProvider: React.FC<AubergineProviderProps> = ({
  children,
}) => {
  const { i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [aubergines, setAubergines] = useState<AubergineDef[]>(
    parseAubergines(searchParams.get("aubergines") as string)
  );

  useEffect(() => {
    console.log("aubergines",aubergines)
    if(!aubergines?.length){
      const aubergine = AUBERGINES.aubergines[0]
      setAubergines([{
        l: aubergine.length,
        d: aubergine.diameter,
        x: 2.5,
        y: 0,
        n: aubergine.name,
      }])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  const unit = useMemo(() => {
    return (
      (searchParams.get("unit") as TUnit) ||
      getDefaultUnitByLocale(i18n.language)
    );
  }, [i18n.language, searchParams]);

  const [debouncedAubergines] = useDebounce(aubergines, 500);
  useEffect(() => {
    searchParams.set("aubergines", auberginesInURL(debouncedAubergines));
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAubergines]);

  const setUnit = (unit: TUnit) => {
    searchParams.set("unit", unit);
    setSearchParams(searchParams);
  };
  return (
    <AuberginesContext.Provider
      value={{
        aubergines,
        setAubergines,
        unit,
        setUnit,
      }}
    >
      {children}
    </AuberginesContext.Provider>
  );
};

export default AuberginesContext;
