import { useState, useEffect } from "react";

type BoundedStateOptions = {
  min: number;
  max: number;
};

function useBoundedState(
  initialValue: number,
  options: BoundedStateOptions
): [number, React.Dispatch<React.SetStateAction<number>>] {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (value < options.min) {
      setValue(options.min);
    } else if (value > options.max) {
      setValue(options.max);
    }
  }, [value, options.min, options.max]);

  return [value, setValue];
}

export default useBoundedState;
