interface ViewBox {
  viewX: number;
  viewY: number;
  viewWidth: number;
  viewHeight: number;
}

function applyViewBox(
  canvasRef: React.RefObject<HTMLCanvasElement>,
  viewBox: ViewBox
): void {
  if (!canvasRef.current) return;

  const canvas = canvasRef.current;
  const ctx = canvas.getContext("2d");

  if (!ctx) return;

  const { viewX, viewY, viewWidth, viewHeight } = viewBox;
  const dpr = window.devicePixelRatio || 1;

  // Adjust the canvas size for the device pixel ratio
  const displayWidth = canvas.clientWidth;
  const displayHeight = canvas.clientHeight;
  canvas.width = displayWidth * dpr;
  canvas.height = displayHeight * dpr;

  // Apply CSS size to maintain original display size
  canvas.style.width = displayWidth + "px";
  canvas.style.height = displayHeight + "px";

  // Scale and translate to fit the view box, considering the dpr
  ctx.setTransform(dpr, 0, 0, dpr, 0, 0); // Scale canvas drawing by dpr
  ctx.translate(-viewX * dpr, -viewY * dpr);
  ctx.scale(canvas.width / viewWidth / dpr, canvas.height / viewHeight / dpr);
}

export default applyViewBox;
