import toInchOrCm from "../../helpers/toInchOrCm";
import useAubergines from "../../hooks/useAubergines";
import styles from "./Ruler.module.scss";

const LINES = Array.from({ length: 100 }, (_, index) => index).reverse();
const HEIGHT = 0.1;
const FONT_SIZE = 0.5;
export const RULER_WIDTH = 2;
const FONT_WEIGHT = "700";
const Ruler = () => {
  const {unit} = useAubergines()
  return (
    <>
      <rect
        className={styles.container}
        width={RULER_WIDTH}
        height="100"
        x="0"
        y="-100"
      />
      {LINES.map((i, index) => {
        const delta = RULER_WIDTH * (i % 10 === 0 ? 0.5 : i % 5 === 0 ? 0.3 : 0.25);
        const showMeasure = i % 5 === 0;
        const unitIndex = toInchOrCm(index + 1, unit)
        if(i === 0) return null;
        return (
          <g transform={`translate(${0}, ${-toInchOrCm(100, unit) + unitIndex})`}>
            <g transform={`translate(${0.2}, ${0.3 * FONT_SIZE})`}>
              {showMeasure && (
                <text fontSize={FONT_SIZE} fontWeight={FONT_WEIGHT}>
                  {i}
                </text>
              )}
            </g>
            <rect
              width={delta}
              height={HEIGHT}
              x={RULER_WIDTH - delta}
              y={-0.5 * HEIGHT}
              className={styles.graduation}
            />
          </g>
        );
      })}
    </>
  );
};

export default Ruler;
