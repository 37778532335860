import { useCallback, useState } from "react";
import styles from "./Modal.module.scss";
import Button from "../Button/Button";
import { Icon } from "../Icon/Icon";
import { createPortal } from "react-dom";
import useBodyScrollLock from "../../../hooks/useBodyScrollLock";

export type ModalProps = {
  children?: React.ReactNode;
  isOpen?: boolean;
  close?: () => void;
  className?: string;
};
const Modal = ({ children, className = "", close, isOpen }: ModalProps) => {
  useBodyScrollLock(!!isOpen);
  return isOpen
    ? createPortal(
        <div className={`${styles.container} ${className}`}>
          <div className={styles.backdrop} onClick={close} />
          <div className={styles.modal}>{children}</div>
        </div>,
        document.body
      )
    : null;
};

interface ModalHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  close: any;
}
const ModalHeader = ({
  title,
  close,
  className = "",
  ...props
}: ModalHeaderProps) => {
  return (
    <div className={`${styles.modalHeader} ${className}`} {...props}>
      <div className={styles.modalHeaderTitle}>{title}</div>
      <Button
        variant="transparent"
        Icon={<Icon icon="close" />}
        size="s"
        className={styles.modalHeaderCloseButton}
        onClick={close}
      />
    </div>
  );
};
type ModalBodyProps = React.HTMLAttributes<HTMLDivElement>;
const ModalBody = ({ children, className = "", ...props }: ModalBodyProps) => {
  return (
    <div {...props} className={`${styles.modalBody} ${className}`}>
      {children}
    </div>
  );
};
type UseModalParams = {
  openOnMount?: boolean;
};
const useModal = (props?: UseModalParams) => {
  const { openOnMount = false } = props || {};
  const [isOpen, setIsOpen] = useState(openOnMount);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  return { isOpen, open, close };
};
Modal.Header = ModalHeader;
Modal.Body = ModalBody;
export { useModal };
export default Modal;
