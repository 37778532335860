import { useTranslation } from "react-i18next";
import Modal, { ModalProps } from "../ui/Modal/Modal";
import styles from "./ModalWarning.module.scss";
import Collapse from "../ui/Collapse/Collapse";
type ModalWarningProps = ModalProps & {
  index?: number;
};

const ModalWarning = ({ index, ...props }: ModalWarningProps) => {
  const { t } = useTranslation();
  return (
    <Modal {...props}>
      <Modal.Header
        title={t("websiteNotice.title")}
        close={props.close}
        className={styles.header}
      />
      <Modal.Body className={styles.body}>
        <div className={styles.collapses}>
          <Collapse
            title={t("about-title")}
            className={styles.collapse}
            headerClassName={styles.collapseHeader}
            contentClassName={styles.collapseContent}
          >
            <p>{t("websiteNotice.paragraph1")}</p>
            <p>{t("websiteNotice.paragraph2")}</p>
            <p>{t("websiteNotice.paragraph3")}</p>
            <p>{t("websiteNotice.paragraph4")}</p>
          </Collapse>
          <Collapse
            title={t("privacy.title")}
            className={styles.collapse}
            headerClassName={styles.collapseHeader}
            contentClassName={styles.collapseContent}
          >
            <p>{t("privacy.paragraph")}</p>
          </Collapse>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalWarning;
