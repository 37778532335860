import type { MaterialSymbol } from "material-symbols";
import styles from "./Icon.module.scss";

interface IconProps extends React.HTMLProps<HTMLSpanElement> {
  icon: MaterialSymbol;
}
const Icon = ({ icon, className, ...props }: IconProps) => {
  return (
    <span
      {...props}
      className={`${styles.icon} material-symbols-rounded ${className || ""}`}
    >
      {icon}
    </span>
  );
};

export { Icon };
