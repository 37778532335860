import { useState, useCallback, useEffect, useRef } from "react";
import { Point } from "../types/common";

interface UseMoveHandler {
  handleChangePosition: (oldPosition: Point, movement: Point) => Point;
  x: number;
  y: number;
  stopPropagation?: boolean;
  disabled?: boolean;
  onMove: (point: Point) => void;
  options?: {
    onTouchMove?: (event: any) => void;
    onMouseMove?: (event: any) => void;
  };
}
const useMove = ({
  handleChangePosition,
  x,
  y,
  onMove,
  disabled = false,
  options = {},
  stopPropagation = false,
}: UseMoveHandler) => {
  const [isDragging, setIsDragging] = useState(false);
  const touchRef = useRef<any>({ x: 0, y: 0 });

  const updatePosition = (dx: number, dy: number) => {
    onMove(handleChangePosition({ x, y }, { x: dx, y: dy }));
  };

  const onMouseMove = useCallback(
    (event: any) => {
      if (stopPropagation) {
        event.stopPropagation();
        event.stopImmediatePropagation();
      }
      if (isDragging) {
        options?.onMouseMove?.(event);
        updatePosition(event.movementX, event.movementY);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragging]
  );

  const onTouchMove = useCallback(
    (event: any) => {
      if (stopPropagation) {
        event.stopPropagation();
        event.stopImmediatePropagation();
      }
      if (isDragging) {
        options?.onTouchMove?.(event);

        const touch = event.touches[0];
        const deltaX = touch.clientX - touchRef.current.x;
        const deltaY = touch.clientY - touchRef.current.y;
        if (touchRef.current.x !== 0 && touchRef.current.y !== 0)
          updatePosition(deltaX, deltaY);
        touchRef.current = { x: touch.clientX, y: touch.clientY };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragging]
  );

  const startDragging = useCallback(() => setIsDragging(true), []);
  const stopDragging = useCallback(() => setIsDragging(false), []);

  useEffect(() => {
    if (isDragging && !disabled) {
      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("mouseup", stopDragging);
      window.addEventListener("touchmove", onTouchMove);
      window.addEventListener("touchend", stopDragging);
    }

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", stopDragging);
      window.removeEventListener("touchmove", onTouchMove);
      window.removeEventListener("touchend", stopDragging);
    };
  }, [disabled, isDragging, onMouseMove, onTouchMove, stopDragging]);

  return {
    isDragging,
    onMove,
    onMouseDown: startDragging,
    onTouchStart: startDragging,
    onMouseUp: stopDragging,
    onTouchEnd: stopDragging,
  };
};

export default useMove;
